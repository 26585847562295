@import "../../variables.scss";

.filter-search {
    position: relative;

    .filter-search-input {
        background: rgba(134, 134, 134, 0.08);
        border: 1px solid #BFBFBF;
        box-sizing: border-box;
        backdrop-filter: blur(16px);
        border-radius: 1px;
        padding: 0.6rem 4rem 0.6rem 7.5rem;
        font-weight: 500;
        outline: 1px solid #BFBFBF;
        color: #9b9b9b;
        width: 100%;

        &:focus {
            outline: 1px solid transparent;
            border-color: #acb4ff;
            box-shadow: 0 0 0 0.2rem rgba(44, 65, 255, 0.25);
        }
    }
    input::placeholder {
        letter-spacing: 0px;
        color: #bcbccb;
        font-size: 0.9rem;
    }

    .search-btn {
        background: transparent;
        border: none;
        outline: none;
        position: absolute;
        left: 4rem;
        top: 50%;
        transform: translateY(-50%);
    }
}

.category-filter {
    width: 100%;

    .category-item {
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 3px;
        color: rgba(40, 40, 40, 0.5);
        cursor: pointer;
    }

    .active-category {
        color: $light-black !important;
        border-bottom: 3px solid #2600FF;
    }
}
