@import "../../variables.scss";

.nft-grid-wrapper{
    border: 1px solid rgba(151, 151, 151, 0.5);
    border-top: none;
    border-radius: 0px 0px 4px 4px;
}

.nft-grid-header{
    border-top: 1px solid rgba(151, 151, 151, 0.5);
    border-right: 1px solid rgba(151, 151, 151, 0.5);
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);
    border-radius: 4px 4px 0 0;
    border-left: 7px solid $primary;
    padding: 15px 22px;
    color: $light-black;
    font-weight: bold;
    font-size: 22px;
}