@import "../../variables.scss";

.auth-layout {
    min-height: 100vh;
    background: radial-gradient(
                circle at bottom left,
                rgba(59, 44, 255, 0.1) 30%,
                transparent
            )
            bottom left,
        radial-gradient(
                circle at bottom right,
                rgba(59, 44, 255, 0.1) 20%,
                transparent
            )
            bottom right,
        no-repeat center;
    background-position: left bottom, right bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;

    .left-grid {
        position: relative;
        .verify-img {
            position: absolute;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .content {
            position: absolute;
            left: 200px;
            top: -175px;
            h3 {
                font-weight: 600;
                letter-spacing: 1px;
            }
        }
        .sub-content {
            position: absolute;
            left: 270px;
            top: -10px;

            .sub-content-query {
                color: #87868a;
                font-weight: bold;
            }
        }
    }

    .sub-title {
        color: #9f9f9f;
        font-size: 15px;
        letter-spacing: 2px;
    }

    .sub-desc {
        font-weight: normal;
        font-size: 15px;
        line-height: 22px;
        /* identical to box height */

        text-align: center;

        color: rgba(40, 40, 40, 0.8);
    }

    .froget-mail {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
        color: $body-color;
    }

    .blue-link {
        color: #5245ff;
        text-shadow: 0 1px 1px #5245ff;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
    }

    .red-link {
        color: #ff004d;
        text-shadow: 0 0 2px #ff004d;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.14em;
    }

    .black-link {
        color: $body-color;
        text-shadow: 0 0 1px $body-color;
        letter-spacing: 2px;
    }

    .purple-link {
        color: #bd00ff;
        text-shadow: 0 0 2px #bd00ff;
    }

    .liteblue-link {
        color: #00cad9;
        text-shadow: 0 0 2px #00cad9;
    }
}

.otp-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .otp-input {
        background: rgba(40, 40, 40, 0.08);
        border: 1px solid rgba(40, 40, 40, 0.5);
        width: 60px;
        height: 60px;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: rgba(40, 40, 40, 0.5);
        outline: none;
        text-align: center;
        margin: 0 1.5rem;
    }

    .submit-btn {
        width: 180px;
        height: 48px;
        border-radius: 1px;
        position: relative;
    }

    .purple-link {
        color: #bd00ff;
        text-shadow: 0 0 2px #bd00ff;
    }

}
