.nft-information-container {
    border: 1px solid rgba(83, 83, 83, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 35px 25px;

    .creator-info {
        .creator-badge {
            width: 36px;
            height: 36px;
            margin-right: 5px;
        }
    }

    .nft-actions {
        display: flex;
        .action-menu-content-item {
            padding: 5px 0;

            &.delete {
                color: #ff2876;
                white-space: nowrap;
                margin-left: 5px;
            }
        }
    }

    .add-to-wishlist-btn {
        background-color: #fff;
        border: none;
        outline: none;
        padding: unset;
        .icon {
            margin: 0 5px;
        }
    }

    .auction-placement-content {
        background: #f1f1f1;
        border: 1px solid rgba(151, 151, 151, 0.5);
        box-sizing: border-box;
        backdrop-filter: blur(16px);

        /* Note: backdrop-filter has minimal browser support */
        border-radius: 4px;
        .price-input{
            padding-left: 10px !important;
        }
    }
}
