.skeleton-loader {
    //box-shadow: 0 4px 5px 0 rgba(33, 33, 33, 0.15);
    border-radius: 4px;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 5px;
    background-image: linear-gradient(
        to right,
        rgb(225, 225, 225),
        rgba(225, 225, 225, 0.4)
    );

    &::before {
        content: "";
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 50%;
        background: linear-gradient(
            to right,
            transparent 0%,
            #f2f2f2 50%,
            transparent 100%
        );
        animation: load 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }

    @keyframes load {
        from {
            left: -150px;
        }
        to {
            left: 100%;
        }
    }

    &.text {
        height: 1rem;
        width: 100%;
    }

    &.btn {
        height: 2.5rem;
        width: 4rem;
    }

    &.circle {
        border-radius: 50%;
    }
}