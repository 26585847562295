.action-dropdown {
    position: relative;

    .dropdown-content {
        position: absolute;
        background: white;
        right: 50%;
        top: 50%;
        box-shadow: 0px 4px 12px #3742603a;
        border: 1px solid #d8dbe3;
        border-radius: 6px;
        padding: 10px 0px;
        z-index: 1200;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.2s, opacity .2s linear;
        // change anchor position on mobile
        @media (max-width: 576px) {
            right: unset;
        }
    }

    .show-content {
        visibility: visible;
        opacity: 1;
        transition: opacity .2s linear;
    }
}
