@import "../../variables.scss";

.textfield-root {
    color: $body-color;
    background: rgba(40, 40, 40, 0.08);
    border: 1px solid rgba(40, 40, 40, 0.5);
    backdrop-filter: blur(16px);
    border-radius: 1px;
    width: 100%;
    padding: 0.9rem 2.18rem;
    outline: 1px solid transparent;
    min-height: 54px;

    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &:focus {
        outline: 1px solid transparent;
        border-color: #acb4ff;
        box-shadow: 0 0 0 0.2rem rgba(44, 65, 255, 0.25);
    }

    &::-webkit-input-placeholder {
        color: rgba(40, 40, 40, 0.6);
    }

    &:-ms-input-placeholder {
        color: rgba(40, 40, 40, 0.6);
    }

    &::placeholder {
        color: rgba(40, 40, 40, 0.6);
    }
}

.label-padding {
    padding: 1.3rem 2.18rem 0.5rem 2.18rem;
}

.submit-btn {
    padding: 0.75rem 1.125rem;
    font-weight: 500;
    font-size: 15px !important;
    letter-spacing: 2px;
    color: #fff !important;
    border: none;
    outline: none;
    border-radius: 1px;
    min-width: 150px;
    height: 54px;
    position: relative;

    .button-glow {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        border-radius: 1px;
        height: 100%;
        width: 100%;
    }

    &.w-gradient {
        .button-glow {
            background: $primary;
            filter: blur(1px);
        }
    }

    &:disabled {
        opacity: 0.75;
    }
}

.secondary-btn {
    height: 54px;
}

.input-container {
    position: relative;

    .error-messages {
        position: absolute;
        pointer-events: none;
        top: 45%;
        transform: translateY(-50%);
        padding-right: 1.5rem;
        line-height: 1;
        text-align: right;
        color: #ff003d;
        font-weight: 500;
        font-size: 14px !important;
    }

    .percentage-symbol {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #282828;
    }
}

.input-container:focus-within label {
    transform: translate(0, 4px) scale(0.8);
}

.input-container .filled {
    transform: translate(0, 4px) scale(0.8);
}

.input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 60%) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: rgba(40, 40, 40, 0.5);
    font-size: 16px;
    left: 2.2rem;
    margin-bottom: 0;
}

.custom-checkbox {
    .custom-control-label {
        font-weight: normal;
        font-size: 14px;
        box-shadow: none !important;
        outline: none !important;
        padding-left: 0.75rem;

        &::before {
            border-radius: 3px;
            background: rgba(40, 40, 40, 0.08);
            border: 2px solid rgba(40, 40, 40, 0.5);
            box-shadow: none !important;
            outline: none !important;
            width: 1.25rem;
            height: 1.25rem;
            top: 0.125rem;
        }

        &::after {
            width: 1.25rem;
            height: 1.25rem;
            top: 0.15rem;
        }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background: rgba(40, 40, 40, 0.08);
        border: 2px solid rgba(40, 40, 40, 0.5);
        box-shadow: none;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("/assets/img/tick.svg");
    }
}

input:-webkit-autofill {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

input:-webkit-autofill ~ label {
    transform: translate(0, 4px) scale(0.8);
}

.phone-input {
    width: 100% !important;
    color: $body-color !important;
    background: rgba(40, 40, 40, 0.08) !important;
    border: 1px solid rgba(40, 40, 40, 0.5) !important;
    outline: 1px solid transparent;
    min-height: 54px;
    padding-left: 64px !important;
}

.phone-input-dropdown {
    color: $body-color !important;
    background: rgba(40, 40, 40, 0.2) !important;
    border: 1px solid rgba(40, 40, 40, 0.5) !important;

    .country {
        transition: all 0.2s ease;
        &:hover {
            background: rgba(40, 40, 40, 0.2) !important;
            .country-name {
                color: rgba($color: #fff, $alpha: 0.9);
            }
            .dial-code {
                color: rgba($color: #fff, $alpha: 0.8);
            }
        }
        &.highlight {
            background: rgba(40, 40, 40, 0.5) !important;
            .country-name {
                color: rgba($color: #fff, $alpha: 0.9);
            }
            .dial-code {
                color: rgba($color: #fff, $alpha: 0.8);
            }
        }
    }
}

.flag-dropdown {
    background-color: transparent !important;
    .selected-flag {
        padding: 0 0 0 14px !important;
        min-width: 50px;
        background: rgba(40, 40, 40, 0.05) !important;
        border: 1px solid rgba(40, 40, 40, 0.5) !important;
    }
}

.thumbnail-btn-container {
    width: 100%;
    max-width: 220px;
    .thumbnail-btn-wrapper {
        position: relative;
        padding-top: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .thumbnail-btn {
            position: absolute;
            top: 0;
            left: 0;
            width: 85%;
            height: 85%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(67, 67, 67, 0.08);
            border: 1px solid rgba(83, 83, 83, 0.3);
            box-sizing: border-box;
            backdrop-filter: blur(8px);

            border-radius: 10px;

            .filepond--drop-label {
                display: none;
            }

            .filepond--browser {
                height: 100%;
            }

            .filepond--file {
                display: block;
            }
            .filepond--panel-bottom,
            .filepond--panel-top {
                min-height: 2.5rem;
            }

            &::after {
                content: "";
                position: absolute;
                top: 37.5%;
                left: 37.5%;
                width: 25%;
                height: 25%;
                background: url("/assets/icon/hg-nft-add-icon.svg") center no-repeat;
                background-size: contain;
                z-index: 3;
                pointer-events: none;
            }
        }
    }
}

.nft-selector-container {
    background: rgba(67, 67, 67, 0.08);
    border: 1px solid rgba(83, 83, 83, 0.3);
    border-radius: 10px;
    padding: 2rem;
    text-align: center;

    .title {
        font-weight: 700;
        font-size: 120%;
        line-height: 30px;
        color: #282828;
    }

    .subtitle {
        font-weight: 400;
        font-size: 73%;
        line-height: 19px;
        color: rgba(40, 40, 40, 0.5);
    }

    &.hide-overlay {
        .filepond--wrapper::after {
            opacity: 0;
        }
    }

    .filepond--wrapper {
        position: relative;
        .filepond--root {
            margin: 1.5rem 0.25rem;
        }
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 50%;
            width: 50%;
            min-height: 2rem;
            min-width: 2rem;
            background: url("/assets/icon/hg-image-placeholder-icon.svg") center no-repeat;
            background-size: contain;
            pointer-events: none;
            z-index: 2;
        }
    }
}
.nft-selector {
    .filepond--drop-label {
        box-sizing: border-box;
        backdrop-filter: blur(8px);
    }
}

.social-container {
    flex-direction: column;
    .social-title-container {
        background: rgba(196, 196, 196, 0.1);
        min-width: 250px;
        // border: 1px solid #d0d0d0;
        backdrop-filter: blur(16px);
        border-radius: 4px;
        .social-icon {
            width: 24px;
            height: 24px;
        }
        .social-title {
            font-weight: 500;
            font-size: 18px;
            color: #2e2e2e;
            width: 60%;
            text-align: left;
        }
    }
    .social-link-input {
        outline: none;
        &::placeholder {
            font-weight: 500;
            font-size: 16px;
            color: rgba(40, 40, 40, 0.5);
        }
    }
    .add-btn {
        position: relative;
        border: none;
        outline: none;
        font-size: 15px;
        text-align: center;
        letter-spacing: 0.14em;
        color: #ffffff;
        .button-glow {
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            border-radius: 1px;
            height: 100%;
            width: 100%;
        }

        &.w-gradient {
            .button-glow {
                background: $primary;
                filter: blur(1px);
            }
        }
    }
}

.find-more-btn {
    background: #ffffff;
    width: 250px;
    border: 2px solid #cacaca;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #2e2e2e;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
