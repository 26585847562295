@import "../../variables.scss";

.auth-layout {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;

    .left-grid {
        position: relative;
        .main-img {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        .content {
            position: absolute;
            left: 200px;
            top: -150px;
            h3 {
                font-weight: 700;
                letter-spacing: 1px;
                color: $body-color;
            }
        }

        .sub-content {
            position: absolute;
            left: 270px;
            top: -10px;

            .sub-content-query {
                color: #87868a;
                font-weight: bold;
            }

            .toggle-link {
                font-size: 18px;
                font-weight: 600;
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                position: relative;
            }

            .blue-link {
                color: #BD00FF;
                text-shadow: 0 0 2px #BD00FF !important;

                &::after{
                    background-color: #BD00FF;
                }
            }

            .red-link {
                color: #FF005C;
                text-shadow: 0 0 2px #FF005C;

                &::after{
                    background-color: #FF005C !important;
                }
            }

        }
    }

    .green-link {
        color: #00C2FF;
        text-shadow: 0 0 2px #00C2FF;

        &::after{
            background-color: #00C2FF !important;
        }
    }   

    .purple-link {
        &::after{
            background-color: #bd00ff !important;
        }
    }

    .toggle-link{
        font-size: 18px;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.1em;
            background-color: gray;
            transition: opacity 300ms, transform 300ms;
            opacity: 1;
            transform: scale(0);
            transform-origin: center;
        }

        &:hover::after,
        &:focus::after {
            transform: translate3d(0, 0.2em, 0);
            transform: scale(1);
        }
    }

    .auth-title {
        letter-spacing: 2px;
    }

    .sub-title {
        color: #9f9f9f;
        font-size: 13px;
        letter-spacing: 2px;
    }

    .froget-password {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
    }

    .white-link{
        color: #fff;
        text-shadow: 0 0 2px #fff;
    }

    .verify-img{
        display: block;
        margin-left: auto;
        margin-right: auto;

    }
}