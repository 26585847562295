@import "../../variables.scss";

.navbar {
    position: relative;
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    padding: 1.25rem 1rem;
    background-color: transparent;

    .filter-bar-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
    }
    &.fix-search {
        background-color: white;
        .filter-wrapper {
            opacity: 1;
            -webkit-appearance: unset;
            .filter-container {
                width: 40%;
            }
        }
    }
    .filter-wrapper {
        opacity: 0;
        display: flex;
        justify-content: center;
        flex-grow: 1;
        position: relative;
        -webkit-appearance: none;

        .filter-container {
            transition: width 0.3s ease;
            width: 56%;
        }
    }
    .navbar-brand {
        box-shadow: unset;
        background: unset;
        padding: 0;
        .logo-container {
            display: flex;
            .logo-text {
                padding-left: 1rem;
                .title {
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 1.5rem;
                }
                .subtitle {
                    font-size: 0.8rem;
                    font-weight: 500;
                    line-height: 1.5rem;
                }
            }
        }
    }
    .navbar-nav {
        .nav-item {
            letter-spacing: 2px;
            font-weight: 600;
            color: #111111;
            @media (min-width: 1199.98px) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
            @media (min-width: 1399.98px) {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }
    }

    .navbar-toggler {
        border: none;
        outline: none;
    }

    .auth-active,
    .active {
        color: $primary !important;

        &::before {
            content: "";
            display: inline-block;
            width: 11.84px;
            height: 11.17px;
            border-radius: 50%;
            margin-right: 8px;
            background: $primary;
            filter: blur(1px);
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }
    }
}

.profile-nav-wrapper {
    .list-root {
        z-index: 9999;
        visibility: hidden;
        position: absolute;
        right: 0;
        margin: 0px;
        .nav-list {
            max-height: calc(100vh - 72px);
            overflow-y: auto;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 16px;
            background-color: rgb(255, 255, 255);
            color: rgb(4, 17, 29);
            max-width: initial;
            min-width: 220px;
            top: -10px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            max-width: 350px;
            transition-duration: 300ms;
            padding: 0px 0 15px 0;

            ul {
                margin-bottom: 0;
                li {
                    border-bottom: 1px solid rgb(229, 232, 235);
                    list-style: none;
                    font-weight: 500;
                    cursor: pointer;
                    &:hover {
                        background-color: rgb(229, 232, 235);
                    }
                    a {
                        padding: 10px 15px;
                        color: #111111 !important;
                        text-decoration: none;
                        display: block;
                    }
                }
            }
        }
    }
}
