@import "../../../variables.scss";

.custom-payment-form {
    position: relative;
    width: 100%;
    min-height: 550px;

    #wert-module {
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    .loading-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .overlay-text {
            text-align: center;
            max-width: 200px;
        }
    }
}