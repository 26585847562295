$body-bg: #fff;
$body-color: #282828;

$primary: #2C41FF;
$light-blue: rgba(55, 167, 255, 1.0);
$light-grey: rgba(246,248,255,1.0);
$light-black: #282828;
$medium-grey: rgb(177, 172, 172);
$grey-2: rgba(54, 54, 72, 0.1);
$grey-3: rgba(54, 54, 72, 0.3);
$grey-5: rgba(40, 40, 40, 0.8);
$grey-8: rgb(54, 54, 72);
$success: rgba(0,213,146,1.0);
$gradient-bg: linear-gradient(0deg, rgba(54,88,254,1) 0%, $primary 70%);

$enable-responsive-font-sizes: true;
$font-family-base: 'Poppins';

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px,
  // Extra large screen / wider desktop
  xxl: 1920px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1199px,
  xxl: 1549px
);