.edit-profile-page {
    .left-panel {
        font-weight: bold;
        border-right: 2px solid #D5D5D5;
        padding-right: 2rem;
    }

    .pro-image {
        padding-bottom: 8rem;
    }

    .basic-info {
        padding-bottom: 16rem;
    }

    .transaction-credit {
        padding-bottom: 3rem;
    }

    .earnings {
        padding-bottom: 3rem;
    }

    .crypto-wallets {
        padding-bottom: 3rem;
    }

    .right-panel {
       
    }

    .middle-panel-wrapper {
        margin-top: 9rem;

        @media (max-width: 768px) {
            margin-top: 3rem;
        }
    }

    .input-class {
        background: rgba(255, 255, 255, 0.08);
        border: 1px solid rgba(151, 151, 151, 0.5);
        border-radius: 4px;
    }
}