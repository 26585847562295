.image-picker-content {
    width: 95vw;
    max-width: 800px;
    margin: 0.5rem;
}

.image-preview-wrapper{
    position: relative;
    width: 100%;
    padding-top: 100%;

    .image-preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}