@import "../../variables.scss";

.filter-menu-item {
    background: #fff;
    border: 1px solid rgba(151, 151, 151, 0.5);
    border-radius: 10px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 1rem;
    margin-bottom: 0.5rem;

    .menu-item-label {
        margin-bottom: 0;
        font-weight: 500;
        color: rgba(40, 40, 40, 0.8);
    }
    .menu-item-input {

    }
}