.modal-container {
   position: fixed;
   top: 0;
   height: 100vh;
   width: 100vw;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;

   .modal-overlay {
      opacity: 0.28;
      background-color: #009aff;
      z-index: 500;
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
      width: 100vw;

      // background-color: #bf6363;
      filter: opacity(0.5);
   }

   .modal-content-area {

      @media (max-width: 576px) { 
         // mobile screens
         width: 100vw;
      }
      @media (min-width: 576px) {
         // non-mobile screens
         padding: 3rem;
      }
      // background-color: #ffffff;
      // box-shadow: 0px 3px 6px #00000029;
      z-index: 1500;
      height: auto;
      max-height: 100vh;
      width: fit-content;
      overflow-x: auto;

      // border: 1px solid rgba(83, 83, 83, 0.3);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

      border-radius: 10px;

      position: relative;
      display: flex;
      flex-direction: column;
      pointer-events: auto;
      background-color: #fff;
   }
}

.spinner {
   position: fixed;
   z-index: 110;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 768px) {
   .otp-container {
      input {
         width: 60px !important;
      }
   }
}

@media screen and (max-width: 768px) {
   .otp-container {
      input {
         font-size: 6vw !important;
         width: 10vw !important;
         height: 10vw !important;
         margin-left: 0.5rem !important;
         margin-right: 0.5rem !important;
      }
   }

   .category-filter {
      overflow-x: scroll;

      &::-webkit-scrollbar {
         width: 0; /* Remove scrollbar space */
         background: transparent; /* Optional: just make scrollbar invisible */
      }

      .category-item {
         margin-right: 15px;
      }
   }
}
