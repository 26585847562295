@import "../../variables.scss";

.sell-nft-page {
    label {
        font-size: 17px;
        font-weight: 500;
    }

    input {
        color: $body-color;
        background: rgba(40, 40, 40, 0.08);
        border: 1px solid rgba(40, 40, 40, 0.5);
        backdrop-filter: blur(16px);
        border-radius: 1px;
        padding: 0.9rem 2.18rem;
        outline: 1px solid transparent;
        min-height: 54px;
    }
}

.nft-details-page {
    .nft-detail-wrapper {
        border: 1px solid rgb(229, 232, 235);
        border-radius: 6px;
        padding: 15px;

        .owner-text {
            font-size: 14px;
            color: rgb(145, 143, 143);
        }
    }
}

// mint nft page styles

.mint-nft-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 7.5rem;
    .pen-tip-down {
        margin-top: 76px;
        margin-bottom: 26px;
    }
    
    .small-text {
        max-width: 700px;
        text-align: center;
    }
    
    .nft-file-selector {
        width: 100%;
        max-width: 500px;
    }
}

.view-nft-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 7.5rem;
    
    .spaced-letters {
        text-align: center;
        letter-spacing: 0.3em;

        color: rgba(40, 40, 40, 0.75);
    }
}

.border-md-right {
    border-right: 1px solid #dee2e6;
    @media (max-width: 768px) {
        border-right: unset;
    }
}