@import "../../variables.scss";

.footer {
    width: 100%;

    background: url("/assets/background/vector/footer-bg.png");
    height: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    .ribbon {
        height: 4px;
        width: 100%;
        background: $primary;
    }
    .footer-content {
        width: 100%;
        padding: 4vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background: rgba(255, 255, 255, 0.12);
        // backdrop-filter: blur(16px);
        .org-info {
            padding: 3vw;
            .logo-container {
                display: flex;
                .logo-text {
                    padding-left: 1rem;
                    .title {
                        font-size: 1.5rem;
                        font-weight: 700;
                        line-height: 1.5rem;
                    }
                    .subtitle {
                        font-size: 0.8rem;
                        font-weight: 500;
                        line-height: 1.5rem;
                    }
                }
            }
            .copyright-info {
                padding: 10% 0;
                font-size: 75%;
            }

            .social-buttons {
                display: flex;
                .social-button {
                    width: 2.25rem;
                    height: 2.25rem;
                    margin-right: 0.5rem;
                    border: 1px solid $body-color;
                    color: $body-color;
                    background: transparent;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    filter: invert(1);
                    cursor: pointer;
                }
            }
        }
        .more-info {
            display: flex;
            color: $body-color;
            padding: 3vw 2vw;
            .link {
                color: $body-color;
                display: block;
            }
            .title {
                font-size: 13px;
                line-height: 19px;
                letter-spacing: 0.18em;
            }
            .site-info {
                padding: 0 1vw;
                font-size: 14px;
                line-height: 41px;
                display: block;
            }
            .policy-info {
                padding: 0 1vw;
                font-size: 14px;
                line-height: 41px;
                display: block;
            }
        }
    }
}
