.nft-thumbnail-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
        height: unset;
        padding-top: 100%;
        margin-bottom: 1rem;
    }

    .nft-thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top;
        border-radius: 5px;
    }

    .nft-thumbnail-skeleton-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top;
        border-radius: 5px;
        .skeleton-loader {
            margin: 0;
        }
    }
}
