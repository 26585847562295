@import "../../../variables.scss";

.title {
    font-size: 90%;
    margin-top: 0.5rem;
    color: $medium-grey;
    letter-spacing: 2px;
}

.content {
    color: $light-black;
    font-weight: 300;
}

.external-link-icon {
    height: 1.5rem;
    width: 1.5rem;
    -webkit-mask: url("/assets/icon/hg-external-link.svg") center no-repeat;
    -webkit-mask-size: contain;
    background-color: $primary;
}