.collapsible-record-root {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(83, 83, 83, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    .info {
        flex-grow: 1;
        flex-wrap: wrap;
        padding: 1.5rem;
        display: flex;
    }
    .actions {
        padding: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid rgba(83, 83, 83, 0.3);
        .action-btn {
            outline: none;
            border: none;
            background-color: transparent;
        }
    }

    &.show {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
    }
}

.collapsible-record-content {
    border: 1px solid rgba(83, 83, 83, 0.3);
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
}
