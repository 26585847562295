.filter-search-wrapper {
    transition: width 0.3s ease;
    width: 59%;
    &.show {
        width: 100%;
    }
}

.filter-bar-wrapper {
    background-color: white;
    width: 100%;
    opacity: 0;
    &.show {
        opacity: 1;
    }
}

.nft-page-content {
    background-color: #f8f9fa;
    padding-bottom: 200px;
}