.filter-bar {
    border: 1px solid rgba(#535353, 0.3);
    // border-radius: 10px;
    .filter-options {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .filter-menu-container {
            position: relative;
            .drop-down {
                position: absolute;
                top: calc(100%);
                // right: 0;
                min-width: 100%;
                max-height: 450px;
                overflow-y: scroll;
                background: #f0f0f0;
                border: 1px solid #bfbfbf;
                border-radius: 0 0 10px 10px;
                box-shadow: 6px 13px 11px rgba(0, 0, 0, 0.17);
                z-index: 1;
                left: 50%;
                transform: translateX(-50%);
                &::-webkit-scrollbar {
                    width: 0px;
                }
                ul {
                    li {
                        font-weight: 500;
                        font-size: 16px;
                        color: #282828;
                        cursor: pointer;

                        &:not(:last-child) {
                            &.horizontal-line {
                                border-bottom: 1px solid rgba(83, 83, 83, 0.3);
                            }
                        }
                    }
                    .filter-list {
                        position: relative;
                        min-width: 250px;
                        width: max-content;
                        background: #ffffff;
                        border: 1px solid rgba(83, 83, 83, 0.3);
                        border-radius: 10px;

                        &.remove-border-radius {
                            border-radius: 10px 10px 0 0;
                        }
                    }
                }
            }
            .filter-option {
                position: relative;
                width: max-content;
                border: 1px solid rgba(#535353, 0.3);
                border-radius: 10px;
                margin: 0.25rem;

                &:nth-last-child(2),
                &:last-child {
                    border-radius: 8px;
                }
                &.remove-border-radius {
                    border-radius: 10px 10px 0 0;
                }
                .filter-title {
                    width: max-content;
                }
                .menu-title {
                    font-weight: 600;
                    font-size: 18px;
                    color: #282828;
                    text-align: left;
                }
                .selected-option {
                    font-weight: 400;
                    font-size: 16px;
                }
                .vertical-line {
                    border-right: 1px solid rgba(#535353, 0.3);
                    min-width: 150px;
                }
                .icon {
                    width: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}

.collapsible-form-content {
    background: #ffffff;
    border: 1px solid rgba(83, 83, 83, 0.3);
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;

    .input-label {
        font-weight: 500;
        font-size: 12px;
        color: rgba(40, 40, 40, 0.36);
    }

    input {
        &::placeholder {
            font-weight: 500;
            font-size: 16px;
            color: rgba(40, 40, 40, 0.5);
        }
    }
    .button {
        background: #000000;
        border-radius: 1px;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #ffffff;
    }

    .form-check-group {
        background: rgba(255, 255, 255, 0.08);
        border: 1px solid rgba(151, 151, 151, 0.5);
        border-radius: 4px;
        .check-label {
            font-weight: 500;
            font-size: 16px;
            color: rgba(40, 40, 40, 0.8);
        }
    }

    .search-more {
        font-weight: 500;
        font-size: 16px;
        color: rgba(40, 40, 40, 0.8);
        text-align: center;
        border-top: 1px solid rgba(83, 83, 83, 0.3);
    }
}
