@import "../../variables.scss";

.button {
    position: relative;
    font-weight: 600;
    letter-spacing: 0.14em;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 1px;
    padding: 0.5rem 1.25rem;
    .button-glow {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        border-radius: 1px;
        height: 100%;
        width: 100%;
    }
    &.w-gradient {
        .button-glow {
            background: $primary;
            filter: blur(2px);
        }
    }
}
