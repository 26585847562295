

.rectangle {
    width: 63.75rem;
    height: 10.95rem;
    background: rgba(242, 242, 242, 1);
    backdrop-filter: blur(16px);

}
.font{
    color: rgba(159, 159, 159, 0.8);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.button-learn-more{
    width: 12.5rem;
    height: 3.25rem;
    border: 2px solid #282828;
    box-sizing: border-box;
    border-radius: 1px;
}
