@import "../../variables.scss";

.ArtCardItem {
    padding: 0.8rem;
    background: rgba(67, 67, 67, 0.08);
    border: 1px solid rgba(83, 83, 83, 0.3);
    border-radius: 0.625rem;
    width: 17.5rem;
    margin: auto;
    transition: all 0.25s ease-in-out;
    will-change: auto;

    &.pending {
        filter: saturate(20%);
        animation: pulse_fade 2s ease-in-out 1s infinite;
    }

    .card-img-wrapper {
        position: relative;
        width: 100%;
        padding-top: 62%;
        .card-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;

            .skeleton-loader {
                margin: 0;
            }
        }
        .card-badge {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 2.25rem;
            height: 2.25rem;
            transform: translate(25%, 50%);
        }
    }
}

.ArtCardContent {
    margin-top: 1.25rem;
    .ArtCardName {
        font-weight: 600;
        font-size: 125%;
        line-height: 150%;
    }
    .ArtCardBid {
        font-weight: normal;
        font-size: 87.5%;
        line-height: 150%;
        color: $body-color;
    }
    .ArtCard-Footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer-section {
            //width: 50%;
            .ArtCardMoneyRate {
                font-weight: bold;
                font-size: 125%;
                line-height: 150%;
                color: $body-color;
            }
            .ArtCardBtn {
                font-weight: 500;
                letter-spacing: 1px;
                font-size: 15px;
                padding: 0.55rem 1.25rem;
                color: #fff;
                border: none;
                outline: none;
                border-radius: 1px;
            }
        }
        .btn-primary {
            box-shadow: 0 0 3px #2C41FF;
        }
    }

    @keyframes pulse_fade {
        0% { opacity: 0.8; }
        50% { opacity: 0.5; }
        75% { opacity: 0.8; }
        100% { opacity: 0.8; }
    }
}

